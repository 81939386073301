import React from "react";
import Header from "./sharedComponents/Header";
import Footer from "./sharedComponents/Footer";
import { Flex } from "@chakra-ui/react";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <Flex flexDirection="column" minHeight="100vh">
      <Header />
        <div style={{ flexGrow: 1, marginTop: 24 }}>{children}</div>
      <Footer />
    </Flex>
  );
};

export default Layout;
