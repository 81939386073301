import React from "react";
import { Box, Link, Text } from "@chakra-ui/react";
import useI18n from "../../hooks/useI18n";

const Footer: React.FC = () => {
  const { t } = useI18n("footer");
  return (
    <Box as="footer" textAlign="center" p="1rem">
      <Text>
        <Link href="/">{t("terms")}</Link> |{" "}
        <Link href="/">{t("privacy")}</Link>
      </Text>
    </Box>
  );
};

export default Footer;
