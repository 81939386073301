import React from "react";
import { Box, Image, Flex } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import LogoImage from "../../images/EYA_logo.svg"; // Adjust the import based on your folder structure

const Logo: React.FC<{ size?: string }> = ({ size = "110px" }) => {
  return (
    <Box>
      <Link to="/">
        <Flex align="left" justifyContent="left">
          <Image boxSize={size} src={LogoImage} alt="EYA Logo" />
        </Flex>
      </Link>
    </Box>
  );
};

export default Logo;
