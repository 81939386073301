import React from "react";
import { Box, Flex, Spacer, Button, useMediaQuery } from "@chakra-ui/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BurgerMenu from "./BurgerMenu";
import { MdExitToApp } from "react-icons/md";

import { Select } from "@chakra-ui/react";
import useI18n from "../../hooks/useI18n";
import Logo from "./Logo";

const Header: React.FC = () => {
  const navigate = useNavigate();
  const handleLoginClick = () => {
    // window.location.replace("https://app.your-ability.com"); //while under development
    navigate("/login");
  };

  const [isMobileDevice] = useMediaQuery("(min-width: 768px)");
  const location = useLocation();

  const ml = isMobileDevice ? "8vw" : "5vw";

  const { t, changeLanguage } = useI18n("header");

  const handleLanguageChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    changeLanguage(event.target.value);
  };

  return (
    <Flex
      as="header"
      alignItems="center"
      fontFamily="Poppins Polly, sans-serif"
    >
      <Box ml={ml}>
        <Logo size="110px" />
      </Box>

      <Spacer />

      {isMobileDevice ? (
        <Flex align="center" gap={8} fontSize={20} color="#2b143b8c" mr="7vw">
          <Select
            defaultValue={"en"}
            onChange={handleLanguageChange}
            width="auto"
          >
            <option value="en">🇬🇧 EN</option>
            <option value="uk">🇺🇦 UA</option>
          </Select>

          <Box
            _hover={{ color: "#2b143b" }}
            color={location.pathname === "/contact-us" ? "#2b143b" : undefined}
          >
            <Link to="/contact-us">{t("contactUs")}</Link>
          </Box>
          <Box
            _hover={{ color: "#2b143b" }}
            color={location.pathname === "/faq" ? "#2b143b" : undefined}
          >
            <Link to="/faq">{t("faq")}</Link>
          </Box>
          <Box
            _hover={{ color: "#2b143b" }}
            color={location.pathname === "/blog" ? "#2b143b" : undefined}
          >
            <Link to="/blog">{t("blog")}</Link>
          </Box>

          <Button
            onClick={handleLoginClick}
            rightIcon={<MdExitToApp />}
            fontWeight="bold"
            color="rgba(41, 49, 28, 1)"
            // color="#7400f6"
            cursor="pointer"
            background="none"
            border="none"
            fontSize={20}
            _hover={{ textDecoration: "underline" }}
          >
            {t("login")}
          </Button>
        </Flex>
      ) : (
        <Flex>
          <Select
            defaultValue={"en"}
            onChange={handleLanguageChange}
            width="auto"
            mr="2vw"
          >
            <option value="en">🇬🇧 EN</option>
            <option value="uk">🇺🇦 UA</option>
          </Select>

          <Box mr="7vw">
            <BurgerMenu />
          </Box>
        </Flex>
      )}
    </Flex>
  );
};

export default Header;
