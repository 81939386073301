import * as React from "react";
import { Route, Routes } from "react-router-dom";
import { Suspense } from "react";
import Layout from "./Layout";
import CenteredLoader from "./sharedComponents/CenteredLoader";

const MainPage = React.lazy(() => import("./main/components/MainPage"));
const ContactUsPage = React.lazy(
  () => import("./contactUs/components/ContactUsPage"),
);
const FAQPage = React.lazy(() => import("./faq/components/FAQPage"));
const BlogPage = React.lazy(() => import("./blog/components/BlogPage"));
const PostPage = React.lazy(() => import("./blog/components/PostPage"));
const UnderDevelopmentPage = React.lazy(
  () => import("./sharedComponents/UnderDevelopmentPage"),
);

const AppRouter = () => {
  return (
    <Suspense fallback={<CenteredLoader />}>
      <Layout>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/contact-us" element={<ContactUsPage />} />
          <Route path="/faq" element={<FAQPage />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blog/:id" element={<PostPage />} />
          <Route path="/login" element={<UnderDevelopmentPage />} />
          <Route path="*" element={<MainPage />} />
        </Routes>
      </Layout>
    </Suspense>
  );
};

export default AppRouter;
