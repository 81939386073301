import React from "react";
import { Flex, Spinner } from "@chakra-ui/react";

const CenteredLoader = () => (
  <Flex
  height="50vh" // Full viewport height
  width="100vw" // Full viewport width
  justifyContent="center" // Center horizontally
  alignItems="center" // Center vertically
  >
    <Spinner size="xl" thickness="4px" speed="0.65s" color="rgba(41, 49, 28, 1)" />
  </Flex>
);

export default CenteredLoader;
